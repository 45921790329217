body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8fc;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
  color: unset;
}

.dropzone {
  height: 100%;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  border: 2px dashed #676767;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

textarea {
  resize: none;
  width: 100%;
  height: 200px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
}

#quest:hover {
  background: #cbcbcb;
}
